<template>
  <div class="container">
    <div class="card stage-min-size overlapping-container has-top-shadow">
      <div class="card-content pb-6 mb-5 px-4 pt-1">
        <CardNavigation/>
        <ViewsStat v-if="!showSingleChallengeView" :pageDetails="{pageName: 'stage', stage: currentStage}"/>
        <div class="is-flex is-justify-content-center is-align-items-center mb-4">
          <div class="is-flex is-justify-content-space-between is-align-items-center" style="width:  100%;">
            <div class="">
              <i v-if="currentStage > 1" @click="changeStage(-1)" class="fas is-size-4 fa-arrow-circle-left pointer is-pulled-left"></i>
              <i v-else class="fas is-size-4 has-text-white fa-arrow-circle-left is-pulled-left"></i>
            </div>
            <div class="is-flex-direction-column is-align-items-center stage-title-modifier">
              <div
                v-if="!showSingleChallengeView"
                class="notranslate is-size-4 has-text-weight-semibold has-baskerville-font has-text-brand-color has-text-centered"
              >
                {{ stageDetails.title ? stageDetails.title : `Chapter ${currentStage}` }}
              </div>
              <div v-else class="is-size-4 has-text-weight-semibold has-baskerville-font has-text-brand-color has-text-centered">
                {{ stageDetails.challenges[0].name }}
              </div>
              <div v-if="timeLimitText && adventureTeam.stage === currentStage" :key="timeLimitText" class="container has-text-centered">
                <div class="is-size-6 has-text-weight-semibold has-text-grey">
                  <i class="fas fa-fw fa-stopwatch"></i> <b>{{ timeLimitText }}</b>
                </div>
              </div>
              <!-- <div v-if="timeLimitText && adventureTeam.stage === currentStage && stageDetails.auto_forward_when_time_is_up" class="has-text-centered">
                <div class="is-size-7 has-text-weight-semibold has-text-grey">
                  <i class="fas fa-forward"></i>
                  Pushed to next {{ $store.state.uiMods.chapter.toLowerCase() }} when time is up
                </div>
              </div> -->
            </div>
            <div class="">
              <i v-if="currentStage < adventureTeam.stage" @click="changeStage(1)" class="fas is-size-4 fa-arrow-circle-right pointer is-pulled-right next-stage-nav-modifier mb-1"></i>
              <i v-else-if="adventureTeam.completedAt && currentStage === adventureTeam.stage" @click="goToDebrief()" class="fas is-size-4 fa-arrow-circle-right pointer next-stage-nav-modifier is-pulled-right"></i>
              <i v-else class="fas is-size-4 has-text-white fa-arrow-circle-right next-stage-nav-modifier is-pulled-right"></i>
            </div>
          </div>
          <!-- <div class="columns is-mobile m-0 p-0 is-vcentered">
            <div class="column py-0 px-2 has-text-centered pr-3">
            </div>
            <div class="column py-0 is-narrow has-text-centered">
            </div>
            <div class="column p-0 px-2 has-text-centered pl-3">
            </div>
          </div> -->
        </div>
        <div class="notranslate" v-if="stageDetails.briefing" :key="currentStage + stageDetails.briefing" v-markdown>{{ stageDetails.briefing }}</div>
        <div v-if="panellumData" class="mt-3" :key="`panellum-${currentStage}`">
          <Pannellum
          :srcInput="panellumData"
          :showZoom="true"
          style="height: 500px;"
          :zoom="0"
          ></Pannellum>
        </div>
        <!-- <DigitalBoard
          v-show="panellumData"
          :srcInput="panellumData"
          :showZoom="true"
          style="height: 500px;"
          :zoom="0"
        ></DigitalBoard> -->
        <div v-if="showTaskMap" class="container mt-5 mb-3">
          <task-map :key="currentStage" :mapHeight="adventureTeam.uiMods.tasksMapHeight || 250"/>
        </div>
        <div v-if="sortedChallenges && !(sortedChallenges.length === 0 && !taskCategories)" class="container mt-5 mb-3">
          <!-- <div v-if="stageDetails.briefing && !showSingleChallengeView" class="is-size-5 has-text-weight-semibold">
            Select {{ $store.state.uiMods.task.toLowerCase() }}{{ stageDetails.challenges.length === 1 ? "" : "s" }} below
          </div> -->
          <Challenge v-if="showSingleChallengeView" :showSingleChallengeView="true" :stageIndexProp="this.currentStage" :challengeIndexProp="1" :key="currentStage"/>
          <!-- <div v-if="stageDetails.briefing && stageDetails.challenges.length > 1 && currentStage === adventureTeam.stage && !$store.state.uiMods.hidePointsStarsWording" class="is-size-7">
            {{ adventureTeam.stageDetails.min_points - adventureTeam.stagePoints }} more {{ $store.state.uiMods.point.toLowerCase() }}{{adventureTeam.stageDetails.min_points - adventureTeam.stagePoints === 1 ? '' : 's'}} to advance
          </div> -->
        </div>
        <div v-if="!showSingleChallengeView && sortedChallenges && taskCategories && taskCategories.length > 1" class="container mb-4" :class="{ 'horizontal-scroll': !adventureTeam.uiMods.wrapOverflowedTaskCategories }">
          <div
          v-for="(category, index) in taskCategories"
          :key="index + taskCategoryFilter"
          class="notranslate button is-small is-rounded mr-1"
          :class="{'is-primary': !taskCategoryFilter && category === 'Show All' || taskCategoryFilter && category === taskCategoryFilter, 'mt-1': adventureTeam.uiMods.wrapOverflowedTaskCategories}"
          @click="toggleTaskCategoryFilter(category)"
          >
          {{ category }}
          <i v-if="!incompleteTaskCategories.includes(category) && category !== 'Show All'" class="fas fa-check ml-1"></i>
          </div>
        </div>
        <div
          v-if="this.$store.state.userName === 'TESTING'"
          class="is-size-7 has-text-danger ml-1 mt-2 mb-2"
        >
          For testing, game timer is set to 10 mins.
          Tasks that are hidden due to certain conditions, such as location-based, hiding next tasks, etc, will also be visible for easier testing.
          This notice is only visible during testing.
        </div>
        <div v-if="sortedChallenges && sortedChallenges.length === 0 && taskCategories" class="container has-text-centered">
          <div class="is-size-7 has-text-grey">
            No accessible {{ $store.state.uiMods.task.toLowerCase() }}s yet.
          </div>
        </div>
        <div v-for="(challenge, index) in sortedChallenges" :key="index" :id="index === 0 ? 'firstTask' : null">
          <div v-if="!showSingleChallengeView" @click="goToTask(challenge.index, challenge, index)" class="columns is-mobile is-vcentered is-gapless is-clickable mb-1">
            <article class="media is-align-items-center mb-3">
              <figure class="media-left pl-1 pt-1">
                <img v-if="challenge.task_list_icon_image" :src="challenge.task_list_icon_image" style="max-width: 32px;" />
                <i v-else-if="challenge.redoTimestamp && (new Date()).getTime() > (new Date(challenge.redoTimestamp))" class="fas fa-sync-alt fa-2x is-success has-text-success"></i>
                <i v-else-if="challenge.redoTimestamp && (new Date()).getTime() < (new Date(challenge.redoTimestamp))" class="fas fa-sync-alt fa-2x is-success has-text-warning"></i>
                <i
                  v-else-if="challenge.completed"
                  class="fas fa-check-circle fa-2x is-success has-text-success"
                  :style="{ color: adventureTeam.uiMods.completedTaskIndicatorColour + ' !important' }"
                ></i>
                <i v-else-if="!challenge.completed && (isHiddenDueToShownIndex(challenge) || (stageDetails.complete_challenges_in_order && index !== 0))" class="fas fas fa-eye-slash is-size-4 has-text-grey"></i>
                <i
                  v-else-if="!challenge.completed && currentStage === adventureTeam.stage && challenge.answerLog && challenge.answerLog.length > 0 && challenge.answerLog.slice(-1)[0].isPendingJudgement"
                  class="spinning fas fa-2x fa-circle-notch pointer mr-2 has-text-primary"
                  :style="adventureTeam.uiMods.pendingTaskIndicatorColour ? { color: adventureTeam.uiMods.pendingTaskIndicatorColour + ' !important' } : {}"
                ></i>
                <i v-else-if="!challenge.completed && currentStage === adventureTeam.stage && challenge.answerLog && challenge.answerLog.length > 0 && !challenge.answerLog.slice(-1)[0].isPendingJudgement && !challenge.answerLog.slice(-1)[0].isValidAnswer" class="fas fas fa-exclamation-circle fa-2x flash-slow has-text-danger"></i>
                <i v-else-if="!challenge.completed && challenge.max_completions_per_session && challenge.completionsLeft === 0" class="fas fas fa-times-circle fa-2x has-text-grey"></i>
                <i
                v-else-if="!challenge.completed && currentStage === adventureTeam.stage"
                class="far fa-circle fa-2x has-text-primary"
                :style="adventureTeam.uiMods.pendingTaskIndicatorColour ? { color: adventureTeam.uiMods.pendingTaskIndicatorColour + ' !important' } : {}"
                ></i>
                <i v-else-if="!challenge.completed && currentStage !== adventureTeam.stage" class="fas fas fa-times-circle fa-2x has-text-grey"></i>
              </figure>
              <div class="media-content">
                <div class="content" :class="{'has-text-grey-light': !challenge.completed && (isHiddenDueToShownIndex(challenge) || (stageDetails.complete_challenges_in_order && index !== 0))}">
                  <div class="notranslate has-text-weight-semibold is-size-6">
                    <i v-if="challenge.position" class="fas fa-map-marked"></i>
                    {{ !challenge.completed && (isHiddenDueToShownIndex(challenge) || (stageDetails.complete_challenges_in_order && index !== 0)) ? '- - -' : challenge.name }}
                    <i v-if="viewsStats && viewsStats[`challenge${currentStage}${challenge.index}`]" class="has-text-grey-light fas fa-sm fa-eye">{{ viewsStats[`challenge${currentStage}${challenge.index}`] }}</i>
                    <i class="fas fa-hand-point-left has-text-grey click-here-left ml-4" v-if="!challenge.completed && !(stageDetails.complete_challenges_in_order && index !== 0) && showScrollDownButton"></i>
                  </div>
                  <div v-if="!challenge.completed && challenge.max_completions_per_session" class="is-size-7">
                    <i class="fas fa-bolt"></i>
                    Can only be done by the first {{ challenge.max_completions_per_session }} {{ adventureTeam.maxPlayersPerTeam && adventureTeam.maxPlayersPerTeam === 1 ? 'player' : 'team' }}{{ challenge.max_completions_per_session > 1 ? 's' : '' }}.
                    {{ challenge.completionsLeft !== null && typeof challenge.completionsLeft !== 'undefined' ? challenge.completionsLeft : challenge.max_completions_per_session }} out of {{ challenge.max_completions_per_session }} left,
                    {{ challenge.completionsLeft === 0 ? 'you are too late...' : 'hurry up!' }}
                  </div>
                  <!-- HACK - remove after June 15 2024 - mo afiq -->
                  <div v-if="challenge.points > 0 && (!$store.state.uiMods.hidePointsStarsWording || adventureTeam.adventureId === 'RAQP')" class="is-size-7">
                    Complete this {{ $store.state.uiMods.task.toLowerCase() }} to get {{ challenge.points }} {{ $store.state.uiMods.point.toLowerCase() }}{{ challenge.points === 1 ? '' : 's' }}
                  </div>
                  <div v-if="shownMembers(challenge.show_specific_players_only)" class="is-size-7">
                    Visible only to {{ shownMembers(challenge.show_specific_players_only) }}
                  </div>
                  <!-- <div class="button is-primary-colors mt-2">
                    Go to task
                  </div> -->
                </div>
              </div>
            </article>
            <hr class="solid">
          </div>
        </div>
        <a
        v-if="showTaskMap && !showAllCompletedTasks && stageDetails.challenges.filter(x => x.completed).length > 3"
        @click="showAllCompletedTasks = true"
        class="button is-rounded is-grey-light has-text-grey has-text-weight-semibold is-small is-outlined mb-2"
        >
          <i class="fas fa-bars mr-2"></i>
          {{ `Show all completed ${ $store.state.uiMods.task.toLowerCase() }s (${stageDetails.challenges.filter(x => x.completed).length -3} hidden)` }}
        </a>
        <div v-if="adventureTeam.showPlayToursBranding" class="is-size-7 has-text-centered has-text-grey-lighter mt-6">
          POWERED BY PLAYTOURS.APP
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
      class="modal"
      v-if="showTimesUpScreen"
      v-bind:class="{'is-active': showTimesUpScreen}"
      >
        <div class="modal-background pointer"></div>
        <div class="modal-content result-modal">
          <div class="card mx-4">
            <div class="card-content">
              <div class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                Time is up!
              </div>
              <div class="container has-text-centered py-3">
                <svg width="168" height="199" viewBox="0 0 168 199" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M141.093 51.4772C156.214 65.0707 161.774 91.7006 155.992 115.099C150.21 138.609 133.199 159 109.962 172.593C86.724 186.187 57.1489 192.983 37.4692 182.287C17.7895 171.59 8.00529 143.512 3.00198 115.991C-1.89014 88.4694 -2.00133 61.3938 11.1185 48.246C24.2382 35.0982 50.4778 35.7667 75.9391 36.4352C101.4 37.1038 126.083 37.8837 141.093 51.4772Z" fill="#F1F1F1"/>
                <path d="M128.752 190.421H123.86V193.652H128.752V190.421Z" fill="#E49D75"/>
                <path d="M152.905 184.421L149.358 187.292L151.944 190.499L155.49 187.628L152.905 184.421Z" fill="#E49D75"/>
                <path d="M131.754 56.7139H131.976C132.977 56.7139 133.755 57.4938 133.755 58.4966V59.388C133.755 60.3908 132.977 61.1708 131.976 61.1708H131.754C130.753 61.1708 129.975 60.3908 129.975 59.388V58.4966C129.975 57.4938 130.753 56.7139 131.754 56.7139Z" fill="#E49D75"/>
                <path d="M133.533 125.796C134.2 127.356 139.314 157.997 140.093 160.894C140.871 163.791 152.879 182.733 153.657 183.847C153.88 185.518 150.21 188.192 148.431 188.192C146.653 186.855 126.195 156.994 125.639 155.657C125.194 154.431 123.526 122.787 133.533 125.796Z" fill="#5FC5D1"/>
                <path d="M112.074 120.67C111.852 121.896 115.298 150.866 115.743 154.766C116.188 158.665 122.859 189.975 123.415 190.755C123.971 191.535 128.418 191.423 129.197 190.978C129.975 190.532 127.64 158.331 127.64 155.991C127.64 153.651 133.644 132.035 132.866 125.796C132.087 119.556 112.074 120.67 112.074 120.67Z" fill="#6BD5E1"/>
                <path d="M129.085 85.6841H124.416V93.8179H129.085V85.6841Z" fill="#E49D75"/>
                <path d="M26.0172 30.4185H100.177C102.957 30.4185 105.181 32.6469 105.181 35.4325V109.751C105.181 112.537 102.957 114.765 100.177 114.765H26.0172C23.2376 114.765 21.0139 112.537 21.0139 109.751V35.321C21.0139 32.6469 23.2376 30.4185 26.0172 30.4185Z" fill="#E0E0E0"/>
                <path d="M68.3785 68.7479C71.4917 65.8509 71.7141 60.9483 68.8233 57.7171C65.9325 54.5972 61.0403 54.3744 57.816 57.2714C54.7028 60.1683 54.4804 65.0709 57.3712 68.3022C57.4824 68.4136 57.7048 68.6364 57.816 68.7479L51.812 93.1493H74.2713L68.3785 68.7479Z" fill="#A4A4A4"/>
                <path d="M36.135 30.4185C36.2462 15.3765 48.4765 3.45429 63.3752 3.56572C78.0516 3.67714 90.0596 15.5993 90.1707 30.4185" stroke="#E0E0E0" stroke-width="6.3799" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M126.195 87.021C129.756 87.021 132.643 83.13 132.643 78.3301C132.643 73.5302 129.756 69.6392 126.195 69.6392C122.633 69.6392 119.746 73.5302 119.746 78.3301C119.746 83.13 122.633 87.021 126.195 87.021Z" fill="#E49D75"/>
                <path d="M125.305 92.1465C117.522 92.1465 110.518 113.762 111.852 121.005C112.297 123.122 130.864 127.467 133.755 126.799C134.756 125.35 138.981 92.1465 125.305 92.1465Z" fill="#FFD98E"/>
                <path d="M123.304 104.18C126.75 101.617 121.191 89.3609 119.857 89.138C117.411 88.8037 111.518 93.3721 111.629 95.2662C111.852 96.3805 119.746 106.743 123.304 104.18Z" fill="#FFE4AD"/>
                <path d="M112.852 94.3748C111.963 93.2605 109.517 87.6894 109.295 86.3524C109.072 85.0153 108.405 65.2936 108.405 64.1794C108.405 63.0651 106.848 60.0567 106.848 58.274C106.848 56.4912 108.627 54.1514 109.517 54.1514C110.518 54.8199 111.852 58.9425 111.852 59.9453C112.185 57.7169 113.742 57.1598 114.187 57.1598C114.631 57.1598 114.52 60.6139 112.741 62.9537C112.852 64.6251 114.965 81.4498 115.298 82.4526C115.632 83.4554 119.635 89.8065 119.635 90.3636C119.746 90.9207 112.852 94.3748 112.852 94.3748Z" fill="#E49D75"/>
                <path d="M82.1068 52.213L81.8057 54.4209L160.461 65.1927L160.762 62.9848L82.1068 52.213Z" fill="#A4A4A4"/>
                <path d="M81.7842 54.4504L80.896 60.9639L85.7431 61.6277L86.6313 55.1142L81.7842 54.4504Z" fill="#A4A4A4"/>
                <path d="M88.6127 55.4607L87.7245 61.9741L90.2582 62.3211L91.1464 55.8077L88.6127 55.4607Z" fill="#A4A4A4"/>
                <path d="M134.089 73.9845C133.866 72.9817 133.755 65.2935 133.978 64.1793C134.311 63.0651 134.978 62.2852 135.423 60.7252C135.534 60.1681 135.312 58.274 134.978 58.1625C134.2 57.9397 134.089 58.9425 133.978 59.611C133.533 61.8395 132.754 61.5052 132.31 61.3938C131.976 61.3938 130.642 61.0595 130.197 61.0595C129.752 61.0595 130.864 61.9509 130.531 63.3994C130.308 64.5136 128.974 72.0903 128.752 73.3159C129.641 73.3159 134.089 73.9845 134.089 73.9845Z" fill="#E49D75"/>
                <path d="M93.0979 56.0403L92.2097 62.5537L94.7434 62.9007L95.6316 56.3873L93.0979 56.0403Z" fill="#A4A4A4"/>
                <path d="M81.8242 54.478L81.5081 56.7964L95.3884 58.6973L95.7046 56.379L81.8242 54.478Z" fill="#A4A4A4"/>
                <path d="M162.886 58.3853C159.661 57.9396 156.659 60.1681 156.214 63.3993C155.77 66.6306 157.993 69.639 161.218 70.0847C164.442 70.5303 167.444 68.3019 167.889 65.0707C168.334 61.8394 166.11 58.831 162.886 58.3853ZM165.999 64.8478C165.665 67.0763 163.664 68.6362 161.44 68.3019C159.216 67.9676 157.66 65.962 157.993 63.7336C158.327 61.5052 160.328 59.9452 162.552 60.2795C164.776 60.6138 166.332 62.6194 165.999 64.8478Z" fill="#A4A4A4"/>
                <path d="M112.852 62.9537C112.519 61.8395 111.963 60.8367 111.852 59.9453C111.741 58.9425 110.406 54.8199 109.517 54.1514C108.627 54.1514 106.848 56.6027 106.848 58.274C106.848 59.6111 107.738 61.5052 108.183 62.8423L112.852 62.9537Z" fill="#E49D75"/>
                <path d="M120.413 72.8704C120.413 75.8788 122.525 77.6616 125.861 78.7758C125.861 82.8984 126.528 83.0098 126.528 85.5726C126.528 90.9208 125.639 88.2467 125.639 97.6061C125.639 103.177 127.974 106.297 132.31 106.297C137.535 106.297 140.537 100.726 140.537 97.829C140.537 94.0406 136.757 87.4667 136.535 82.007C136.201 74.6532 133.866 68.525 128.196 68.525C123.193 68.4135 120.413 70.1963 120.413 72.8704Z" fill="black"/>
                <path d="M126.195 81.2271C127.177 81.2271 127.973 80.429 127.973 79.4444C127.973 78.4598 127.177 77.6616 126.195 77.6616C125.212 77.6616 124.416 78.4598 124.416 79.4444C124.416 80.429 125.212 81.2271 126.195 81.2271Z" fill="#E49D75"/>
                <path d="M155.436 186.855L158.216 190.643L152.434 198.889H146.986L147.097 196.103L149.321 195.1L151.322 190.309L155.436 186.855Z" fill="black"/>
                <path d="M129.085 193.429V199H112.852V196.549L123.526 193.429H129.085Z" fill="black"/>
                </svg>
              </div>
              <div v-if="!stageDetails.auto_forward_when_time_is_up" class="container has-text-centered mb-2">
                Completing tasks will still give your team points for this Chapter, but they will not be part of the final tally.
              </div>
              <div v-if="stageDetails.auto_forward_when_time_is_up" class="container has-text-centered mb-2">
                You will be brought to the next chapter automatically.
              </div>
              <div @click="toggleTimesUpScreen()" class="button is-primary-colors has-text-weight-semibold is-fullwidth">
                Back to {{ $store.state.uiMods.game.toLowerCase() }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <NextStage v-if="showNextStageAlert && !showNextStageButton" />
    <div @click="scrollToBottom()" v-if="showScrollDownButton === 2 && !showSingleChallengeView" class="button has-text-weight-semibold is-small is-success scroll-button-modifier is-rounded">
      <i class="fas fa-angle-double-down mr-2"></i>
      {{ t(`Scroll down to see ${$store.state.uiMods.task.toLowerCase()}s`) }}
      <i class="fas fa-angle-double-down ml-2"></i>
    </div>
    <div @click="goToLastStage()" v-if="showNextStageButton" class="button has-text-weight-semibold is-small is-success next-stage-button-modifier is-rounded">
      {{ t(`Click to go to latest ${$store.state.uiMods.chapter.toLowerCase()}`) }}
      <i class="fas fa-angle-double-right ml-2"></i>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CardNavigation from '@/components/CardNavigation'
import ViewsStat from '@/components/ViewsStat.vue'
import NextStage from '@/components/NextStage.vue'
import TaskMap from '@/components/TaskMap.vue'
import Challenge from '@/views/Challenge.vue'
import Pannellum from '@/components/Pannellum.vue'
// import DigitalBoard from '@/components/DigitalBoard.vue'
import { isInViewport } from '@/utils/utils'

export default {
  name: 'Stage',
  components: {
    ViewsStat,
    CardNavigation,
    NextStage,
    TaskMap,
    Challenge,
    Pannellum,
    // DigitalBoard
  },
  data() {
    return {
      timeLimitText: null,
      timeLimitStage: null,
      showTimesUpScreen: false,
      interval: null,
      scrollDownTimerTimeout: null,
      scrolledToBottom: false,
      showScrollDownButton: false,
      showNextStageButton: false,
      nextStageTimerTimeout: null,
      showAllCompletedTasks: false
    }
  },
  computed: {
    showSingleChallengeView() {
      if(
        this.adventureTeam.uiMods && this.adventureTeam.uiMods.useSingleChallengeView &&
        this.stageDetails &&
        this.stageDetails.challenges.length === 1 &&
        !this.stageDetails.challenges[0].show_specific_players_only &&
        !this.stageDetails.challenges[0].position &&
        !this.stageDetails.challenges[0].access_code &&
        !this.stageDetails.challenges[0].task_category
      ){
        return true
      }
      return false
    },
    stageDetails() {
      if(this.adventureTeam){
        let allStageDetails = []
        allStageDetails.push(this.adventureTeam.stageDetails)
        if(this.adventureTeam.stageDetailsHistory){
          allStageDetails = allStageDetails.concat(
            this.adventureTeam.stageDetailsHistory
          )
        }
        return allStageDetails.find(stageDetails => {
          return stageDetails.stageNumber === this.currentStage
        })
      } else {
        return null
      }
    },
    showTaskMap() {
      if (
        this.stageDetails &&
        !this.adventureTeam?.uiMods?.hideMapInStageScreen
      ) {
        return this.stageDetails.challenges.some(challenge => {
          return !!challenge.position
        })
      } else {
        return false
      }
    },
    viewsStats() {
      return this.$store.state.adventureView ? this.$store.state.adventureView.stats : null
    },
    panellumData() {
      if (this.stageDetails && this.stageDetails.has_virtual_spaces) {
        const scenes = {}
        this.stageDetails.virtual_spaces.forEach(virtualSpace => {
          scenes[virtualSpace.name] = {
            title: virtualSpace.name,
            hfov: 110,
            pitch: 0,
            yaw: 0,
            autoRotate: -2,
            panorama: virtualSpace.imageUrl,
            hotSpots: virtualSpace.adjacentScenes.map(adjacentScene => {
              return {
                title: adjacentScene.name,
                pitch: adjacentScene.y,
                yaw: adjacentScene.x,
                type: 'scene',
                text: adjacentScene.name,
                sceneId: !adjacentScene.imageUrl && !adjacentScene.taskNumber ? adjacentScene.name : null,
                cssClass: adjacentScene.imageUrl ? 'magnifying-glass' : null,
                clickHandlerFunc: () => {
                  if (adjacentScene.taskNumber) {
                    this.goToTask(adjacentScene.taskNumber - 1, null, 0)
                  } else if (adjacentScene.imageUrl) {
                    this.panellumImageClickHandler(adjacentScene.imageUrl)
                  } else {
                    this.$store.commit('updatePanellumLocation', {
                      stage: this.currentStage,
                      sceneName: adjacentScene.name
                    })
                  }
                }
              }
            })
          }
        })
        return {
          default: {
            firstScene: this.panellumLocation[this.currentStage] ? this.panellumLocation[this.currentStage] : this.stageDetails.virtual_spaces[0].name
          },
          scenes
        }
      } else {
        return null
      }
    },
    taskCategories () {
      if (this.stageDetails) {
        let taskCategories = this.stageDetails.challenges.filter(challenge => {
          return challenge.task_category
        }).filter(challenge => {
          if(this.adventureTeam.hiddenTaskCategories) {
            const categories = challenge.task_category.split(';').map(cat => cat.trim())
            return !categories.some(cat => this.adventureTeam.hiddenTaskCategories.includes(cat))
          }
          return true
        }).flatMap(challenge => {
          return challenge.task_category.split(';').map(cat => cat.trim())
        })
        taskCategories = [...new Set(taskCategories)]
        taskCategories.sort()
        if(taskCategories.length === 0){
          return null
        }
        if (this.adventureTeam.uiMods.shuffleTaskCategories) {
          let m = taskCategories.length, t, i;
          let seed = this.adventureTeam.teamCode ? parseInt(this.adventureTeam.teamCode, 36) : 1
          const random = () => {
            var x = Math.sin(seed++) * 10000
            return x - Math.floor(x)
          }
          while (m) {
            i = Math.floor(random() * m--)
            t = taskCategories[m]
            taskCategories[m] = taskCategories[i]
            taskCategories[i] = t
          }
        }
        if (!this.adventureTeam.uiMods.hideShowAllOptionForTaskCategories) {
          taskCategories.push('Show All')
        }
        return taskCategories
      } else {
        return null
      }
    },
    incompleteTaskCategories(){
      if (!this.stageDetails?.challenges) return []
      return this.stageDetails.challenges
      .filter(challenge => !challenge.completed)
      .flatMap(challenge => challenge.task_category ? challenge.task_category.split(';').map(cat => cat.trim()) : [])
      .filter((value, index, self) => {
        return value && self.indexOf(value) === index
      })
    },
    sortedChallenges(){
      if(!this.stageDetails){
        return []
      } else {
        let sortedChallenges = [...this.stageDetails.challenges]
        sortedChallenges.forEach((challenge, index) => challenge.index = index)

        const newSortedChallenges = []
        if(this.stageDetails.shuffle_challenges && this.stageDetails.challenges_sequence){
          this.stageDetails.challenges_sequence.forEach(index => {
            if(sortedChallenges[index]){
              newSortedChallenges.push(sortedChallenges[index])
            }
          })

          const remainingChallenges = sortedChallenges.filter(challenge => {
            !this.stageDetails.challenges_sequence.includes(sortedChallenges.indexOf(challenge))})
          sortedChallenges = newSortedChallenges.concat(remainingChallenges)
        }

        sortedChallenges = sortedChallenges.filter((challenge, index) => {
          if (index === 0) return true
          const prevChallenge = sortedChallenges[index-1]
          if (!prevChallenge.completed && prevChallenge.hide_next_challenge) {
            if (!this.userName.includes('TESTING')) return false
          }
          return true
        })

        const hasHideNextChallenge = sortedChallenges.some(challenge => challenge.hide_next_challenge)
        if (!hasHideNextChallenge) {
          sortedChallenges = sortedChallenges.filter((challenge) => {
            if (!challenge.show_only_after_x_minutes || !this.adventureTeam.stageStartedAt) return true
            const currentTime = Date.now() / 1000
            const timeDifferenceInMinutes = (currentTime - this.adventureTeam.stageStartedAt.seconds) / 60
            if (timeDifferenceInMinutes >= challenge.show_only_after_x_minutes) return true
            return false
          })

          let completedChallenges = sortedChallenges.filter((challenge, index) => challenge.completed && !challenge.redoTimestamp)
          if(!this.showAllCompletedTasks && this.showTaskMap){
            completedChallenges = completedChallenges.slice(0, 3)
          }

          if(!this.stageDetails.complete_challenges_in_order){
            const incompletedChallengesWithPositions = sortedChallenges.filter((challenge, index) => {
              return (!challenge.completed || challenge.redoTimestamp) && challenge.position
            })
            const incompletedChallengesWithoutPositions = sortedChallenges.filter((challenge, index) => {
              return (!challenge.completed || challenge.redoTimestamp) && !challenge.position
            })
            if(
              this.userLocation
            ){
              incompletedChallengesWithPositions.forEach(challenge => {
                challenge.distance = this.distanceBetweenTwoGeoCoordinatesInMeters(
                  this.userLocation.lat, this.userLocation.lon, challenge.position.lat, challenge.position.lon
                )
              })
              incompletedChallengesWithPositions.sort(
                (a, b) => {
                  return a.distance < b.distance ? -1 : 1
                }
              )
            }
            sortedChallenges = incompletedChallengesWithPositions.concat(
              incompletedChallengesWithoutPositions.concat(completedChallenges)
            )
          } else {
            const incompleteChallenges = sortedChallenges.filter((challenge, index) => !challenge.completed)
            sortedChallenges = incompleteChallenges.concat(completedChallenges)
          }
        }

        sortedChallenges = sortedChallenges.filter(challenge => this.isChallengeShown(challenge))

        sortedChallenges = sortedChallenges.filter(challenge => {
          if(
            this.adventureTeam.hiddenTaskCategories &&
            challenge.task_category &&
            this.adventureTeam.hiddenTaskCategories.includes(challenge.task_category)
          ){
            return false
          }
          return true
        })

        if(
          this.adventureTeam.uiMods.autoOpenFirstIncompleteTask
          && sortedChallenges.length > 0
          && !sortedChallenges.completed
          && (
            !this.$router._backRoute.includes('/game/task/') ||
            (
              this.$router._backRoute.includes('/game/task/') &&
              !this.$router._backRoute.includes(`/${this.currentStage}/`)
            )
          )
        ) {
          this.goToTask(sortedChallenges[0].index, sortedChallenges[0], 0)
        }

        return sortedChallenges
      }
    },
    ...mapState([
      'userLocation', 'showNextStageAlert', 'userName', 'currentStage', 'adventureTeam', 'panellumLocation', 'taskCategoryFilter'])
  },
  updated() {
    document.querySelectorAll('img').forEach(item => {
      item.removeEventListener('click', this.showPhotoViewer)
      item.addEventListener('click', this.showPhotoViewer)
    })
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    this.$store.commit('updateCurrentChallengeIndex', null)

    const qrStartAccessCode = localStorage.getItem('playToursQrStartAccessCode')
    if (qrStartAccessCode) {
      localStorage.removeItem('playToursQrStartAccessCode')
      const challenge = this.stageDetails.challenges.find(challenge => challenge.access_code === qrStartAccessCode)
      if(challenge) {
        this.$store.commit('updateLatestAccessCode', qrStartAccessCode)
        this.$router.push({
          name: 'Challenge',
          params: {
            stageIndex: this.$store.state.currentStage,
            challengeIndex: this.stageDetails.challenges.indexOf(challenge) + 1
          }
        })
      }
    }

    if(
      this.stageDetails &&
      this.stageDetails.chapter_background_url
    ){
      document.querySelector('html').style.backgroundImage = `url('${this.stageDetails.chapter_background_url}')`
      document.querySelector('html').style.backgroundPosition = "left top"
      document.querySelector('html').style.backgroundAttachment = "fixed"
    } else if (
      this.stageDetails &&
      !this.stageDetails.chapter_background_url
    ) {
      document.querySelector('html').style.backgroundImage = ''
    }

    this.$store.commit('updateShowTaskAlert', false)

    this.$store.dispatch('enterPage', {
      pageName: 'stage',
      stage: this.currentStage
    })

    document.querySelectorAll('img').forEach(item => {
      item.addEventListener('click', this.showPhotoViewer)
    })

    window.onscroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - 100) {
          this.scrolledToBottom = true
          this.showScrollDownButton = 0
        }
    }

    this.startScrollDownTimer()
    this.startNextStageTimer()
    this.updatePointStars()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
    if(this.interval){
      clearInterval(this.interval)
    }
  },
  created() {
    this.startTimeLimitTimer()
  },
  methods: {
    startScrollDownTimer() {
      this.scrolledToBottom = false
      this.showScrollDownButton = 0
      clearTimeout(this.scrollDownTimerTimeout)
      this.scrollDownTimerTimeout = setTimeout(() => {
        if (
          !this.scrolledToBottom &&
          !this.showScrollDownButton &&
          this.sortedChallenges && this.sortedChallenges.some(challenge => !challenge.completed) &&
          this.currentStage === this.adventureTeam.stage
        ) {
          this.showScrollDownButton = 1
          if (document.getElementById('firstTask') && !isInViewport(document.getElementById('firstTask'))) this.showScrollDownButton = 2
        }
      }, 12000)
    },
    startNextStageTimer() {
      this.showNextStageButton = false
      clearTimeout(this.nextStageTimerTimeout)
      this.nextStageTimerTimeout = setTimeout(() => {
        if (
          this.currentStage !== this.adventureTeam.stage
        ) {
          this.showNextStageButton = true
        }
      }, 12000)
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
      this.showScrollDownButton = 0
    },
    goToLastStage() {
      this.$store.commit('setCurrentStage', this.adventureTeam.stage)
    },
    showPhotoViewer(event){
      this.$store.commit('showPhotoViewer', event.target.dataset.url)
    },
    startTimeLimitTimer() {
      if(this.stageDetails && this.stageDetails.time_limit_timestamp){
        this.timeLimitStage = this.currentStage
        const endTime = this.stageDetails.time_limit_timestamp.toDate()
        this.getTimeLimitText(endTime)
        this.interval = setInterval(() => {
          this.getTimeLimitText(endTime)
        }, 1000)
      }
    },
    onScroll() {
      this.$store.commit('updateScrollPositions', {
        pageName: this.$route.name,
        scrollPosition: window.top.scrollY
      })
    },
    getTimeLimitText(endTime){
      const startTime = new Date();
      let msec = endTime - startTime;
      if(msec > 0){
        const hours = Math.floor(msec / 1000 / 60 / 60)
        msec -= hours * 1000 * 60 * 60;
        const minutes = Math.floor(msec / 1000 / 60)
        msec -= minutes * 1000 * 60;
        const seconds = Math.floor(msec / 1000)
        msec -= seconds * 1000
        const hourString = hours > 0 ? `${hours < 10 ? `0${hours}` : `${hours}`}:` : ''
        const minuteString = `${minutes < 10 ? `0${minutes}` : `${minutes}`}:`
        const secondString = `${seconds < 10 ? `0${seconds}` : `${seconds}`}`
        this.timeLimitText = `${hourString}${minuteString}${secondString}`
      } else {
        if(
          this.stageDetails.auto_forward_when_time_is_up
        ){
          this.$store.dispatch('triggerAutoForward', {
            stage: this.timeLimitStage
          })
        }
        clearInterval(this.interval)
        this.timeLimitText = "TIME'S UP"
      }
    },
    toggleTimesUpScreen() {
      this.showTimesUpScreen = false
    },
    panellumImageClickHandler(imageUrl) {
      this.$store.commit('showPhotoViewer', imageUrl)
    },
    changeStage(change){
      if(
        this.adventureTeam.stageDetails.stageNumber === (this.currentStage + change) ||
        this.adventureTeam.stageDetailsHistory && this.adventureTeam.stageDetailsHistory.some(stageDetails => {
          return stageDetails.stageNumber === (this.currentStage + change)
        })
      ){
        this.toggleTaskCategoryFilter(null)
        this.$store.commit('changeCurrentStage', change)
      } else {
        alert('Unable to change chapters at the moment.')
      }
    },
    goToTask(challengeIndex, challenge, sortedChallengesIndex){
      if(!challenge){
        challenge = this.stageDetails.challenges[challengeIndex]
      }
      if(
        challenge.hide_after_completion &&
        challenge.completed
      ){
        alert(`${this.$store.state.uiMods.task} is hidden.`)
        return
      }
      if(
        this.stageDetails.complete_challenges_in_order &&
        sortedChallengesIndex &&
        sortedChallengesIndex !== 0 &&
        !challenge.completed
      ){
        alert(`${this.$store.state.uiMods.task} not accessible. Please complete the first ${this.$store.state.uiMods.task.toLowerCase()} shown on the list.`)
        return
      }
      if(
        this.isHiddenDueToShownIndex(this.stageDetails.challenges[challengeIndex]) &&
        (this.userName && !this.userName.includes('TESTING'))
      ){
        alert(`${this.$store.state.uiMods.task} not accessible to you. Please communicate with your team members.`)
        return
      }
      this.$store.commit('updateCurrentSortedChallengesIndex', sortedChallengesIndex)
      this.$store.commit('updateCurrentChallengeIndex', challengeIndex)
      if(this.adventureTeam.requireTeamPosition){
        const now = new Date()
        if(
          !this.$store.state.userLocation ||
          this.$store.state.userLocation && (now.getTime() - this.$store.state.userLocationExpiryTimestamp.getTime())/1000 > 60
        ){
          this.$store.dispatch('getUserLocationAction', this.t)
        }
      }
      if(this.userName && !this.userName.includes('TESTING')){
        this.$mixpanel.track('page_visit', {
          adventure_id: this.adventureTeam.adventureId,
          adventure_name: this.adventureTeam.adventureName,
          session_id: this.adventureTeam.sessionId,
          page_name: 'Challenge'
        })
      }
      this.$router.push({
          name: 'Challenge',
          params: {
            stageIndex: this.currentStage,
            challengeIndex: challengeIndex+1
          }
        }
      )
    },
    goToDebrief(){
      this.$router.push({ name: 'Debrief' })
    },
    distanceBetweenTwoGeoCoordinatesInMeters(lat1, lon1, lat2, lon2) {
      if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0
      }
      else {
        const radlat1 = Math.PI * lat1/180
        const radlat2 = Math.PI * lat2/180
        const theta = lon1-lon2
        const radtheta = Math.PI * theta/180
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
        if (dist > 1) {
          dist = 1
        }
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        dist = dist * 1.609344
        return dist*1000
      }
    },
    isHiddenDueToShownIndex(challenge){
      let playerIndexTest = false
      if(challenge.show_specific_players_only){
        const playerIndex = this.adventureTeam.teamMembers.map(obj => obj.name).indexOf(this.userName) + 1
        const shownIndexes = challenge.show_specific_players_only.split(',').map(str => parseInt(str))
        playerIndexTest =  !shownIndexes.includes(playerIndex)
      }
      return playerIndexTest
    },
    isChallengeShown(challenge){
      const positionTest = true
      // let playerIndexTest = true
      let taskCategoryTest = true
      let accessCodeTest = true
      let timeRestrictedTest = true
      let chapterPointsTest = true

      if(
        (this.taskCategoryFilter && challenge.task_category && !challenge.task_category.split(';').map(tc => tc.trim()).includes(this.taskCategoryFilter))
        ||
        (this.taskCategoryFilter && !challenge.task_category)
      ){
        taskCategoryTest = false
      }

      if(challenge.access_code && !challenge.completed){
        accessCodeTest = false
      }

      const checkIfWithinTimeRestrictions = (challenge) => {
        if(
          !this.$store.state.userName.includes('TESTING') &&
          challenge.time_restricted
        ){
          let afterTest = true
          let beforeTest = true
          const afterTime = challenge.time_restricted_allow_after // HH:mm, can be null
          const afterDate = challenge.time_restricted_allow_after_date // YYYY-MM-DD, can be null
          const beforeTime = challenge.time_restricted_allow_before // HH:mm, can be null
          const beforeDate = challenge.time_restricted_allow_before_date // YYYY-MM-DD, can be null

          let afterDateTime = null
          let beforeDateTime = null
          if (afterTime && afterDate) afterDateTime = new Date(`${afterDate}T${afterTime}`)
          if (beforeTime && beforeDate) beforeDateTime = new Date(`${beforeDate}T${beforeTime}`)

          const now = new Date()
          if (afterDateTime) {
            afterTest = now >= afterDateTime
          } else if (afterTime) {
            const nowHours = now.getHours() < 10 ? `0${now.getHours()}` : `${now.getHours()}`
            const nowMinutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : `${now.getMinutes()}`
            const nowTime = `${nowHours}:${nowMinutes}`
            afterTest = nowTime >= afterTime
          } else if (afterDate) {
            const nowDate = now.toISOString().split('T')[0] // YYYY-MM-DD
            afterTest = nowDate >= afterDate
          }
          if (beforeDateTime) {
            beforeTest = now <= beforeDateTime
          } else if (beforeTime) {
            const nowHours = now.getHours() < 10 ? `0${now.getHours()}` : `${now.getHours()}`
            const nowMinutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : `${now.getMinutes()}`
            const nowTime = `${nowHours}:${nowMinutes}`
            beforeTest = nowTime <= beforeTime
          } else if (beforeDate) {
            const nowDate = now.toISOString().split('T')[0] // YYYY-MM-DD
            beforeTest = nowDate <= beforeDate
          }
          return afterTest && beforeTest
        }
        return true
      }
      if (
        !checkIfWithinTimeRestrictions(challenge) &&
        challenge.time_restricted_hide_outside_timeframe &&
        !challenge.completed
      ) {
        timeRestrictedTest = false
      }

      if (challenge.show_only_after_x_chapter_points) {
        if (
          this.adventureTeam.stagePoints < challenge.show_only_after_x_chapter_points &&
          !challenge.show_only_after_x_chapter_points_task_category
        ) chapterPointsTest = false
        if (challenge.show_only_after_x_chapter_points_task_category) {
          const taskCategoryPoints = this.stageDetails.challenges
            .filter(c => c.task_category === challenge.show_only_after_x_chapter_points_task_category && c.completed)
            .reduce((sum, c) => sum + (c.points || 0), 0)
          if (taskCategoryPoints < challenge.show_only_after_x_chapter_points) chapterPointsTest = false
        }
      }

      return ((positionTest && accessCodeTest && timeRestrictedTest && chapterPointsTest) || this.userName==='TESTING') && taskCategoryTest
    },
    shownMembers(taskVisibilityIndexes){
      if(!taskVisibilityIndexes){return null}
      const shownIndexes = taskVisibilityIndexes.split(',').map(str => parseInt(str))
      const shownMemberNames = []
      this.adventureTeam.teamMembers.map(obj => obj.name).forEach((name, index) =>{
        if(shownIndexes.includes(index+1)){
          shownMemberNames.push(name)
        }
      })
      return shownMemberNames.join(', ')
    },
    toggleTaskCategoryFilter(filterName){
      this.$store.commit('updateTaskCategoryFilter', 'oqxh2uriquh')
      setTimeout(() => {
        this.$store.commit('updateTaskCategoryFilter', filterName === 'Show All' ? null : filterName)
      }, 10)
    },
    updatePointStars() {
      if(
        this.$store.state.uiMods.hidePointsSystemSpecificChapters
      ){
        const uiMods = this.$store.state.uiMods
        uiMods.hidePointsStarsWording = this.$store.state.uiMods.hidePointsSystemSpecificChapters
        .replace(/\s/g, '')
        .split(',')
        .includes(
          this.currentStage.toString()
        )
        uiMods.hidePointsProgressBar = uiMods.hidePointsStarsWording
        this.$store.commit('updateUiMods', uiMods)
      }
    }
  },
  watch: {
    timeLimitText(newTimeLimitText, oldTimeLimitText) {
      if(
        oldTimeLimitText !== null &&
        newTimeLimitText === "TIME'S UP" &&
        !this.adventureTeam.stageDetails.time_limit_timestamp
      ){
        this.showTimesUpScreen = true
        }
    },
    stageDetails(newStageDetails, oldStageDetails) {
      if(
        JSON.stringify(oldStageDetails) !== JSON.stringify(newStageDetails) &&
        newStageDetails.chapter_background_url
      ){
        document.querySelector('html').style.backgroundImage = `url('${newStageDetails.chapter_background_url}')`
        document.querySelector('html').style.backgroundPosition = "left top"
        document.querySelector('html').style.backgroundAttachment = "fixed"
      } else if (
        JSON.stringify(oldStageDetails) !== JSON.stringify(newStageDetails) &&
        !newStageDetails.chapter_background_url
      ) {
        document.querySelector('html').style.backgroundImage = ''
      }
    },
    currentStage(newCurrentStage, oldCurrentStage) {
      if (
        oldCurrentStage !== newCurrentStage &&
        newCurrentStage === this.adventureTeam.stage
      ) {
        clearInterval(this.interval)
        this.timeLimitText = null
        this.timeLimitStage = null
        this.startTimeLimitTimer()
      }

      this.startScrollDownTimer()
      this.startNextStageTimer()

      this.updatePointStars()
    },
    userLocation(newUserLocation, oldUserLocation) {
      if (
        newUserLocation && oldUserLocation &&
        (
          newUserLocation.lat !== oldUserLocation.lat ||
          newUserLocation.lon !== oldUserLocation.lon
        ) &&
        (
          this.sortedChallenges && this.sortedChallenges.length > 0 &&
          this.sortedChallenges.some(challenge => !challenge.completed)
        )
      ){
        this.showScrollDownButton = 1
      }
    },
    taskCategories: {
      immediate: true,
      handler(newTaskCategories) {
        if (
          newTaskCategories &&
          !this.taskCategoryFilter &&
          this.adventureTeam?.uiMods?.autoSelectFirstTaskCategory
        ) {
          this.toggleTaskCategoryFilter(newTaskCategories[0])
        }
      }
    }
  }
}
</script>

<style>
hr.solid {
  border-top: 3px solid #000;
}

.magnifying-glass {
  cursor: pointer;
  height: 23px;
  width: 23px;
  opacity: 0.9;
  content: url(https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2Fmagnifying-glass.png?alt=media&token=1c735196-9efd-41e7-92c9-a08d3ff50c93);
}

.pnlm-panorama-info {
  visibility: visible !important;
}

.scroll-button-modifier {
  position: fixed;
  left: 50%;
  bottom: 73px;
  z-index: 2;
  animation: scrollDown 0.7s infinite ease-in-out;
}

@keyframes scrollDown {
  0% {
    transform: translateY(0px) translate(-50%, 0);
  }
  50% {
    transform: translateY(10px) translate(-50%, 0);
  }
  100% {
    transform: translateY(0px) translate(-50%, 0);
  }
}

.next-stage-nav-modifier {
  animation: nextStageAnim 0.7s infinite ease-in-out;
}

.next-stage-button-modifier {
  position: fixed;
  left: 50%;
  bottom: 65px;
  z-index: 2;
  animation: nextStageAnim 0.7s infinite ease-in-out;
}

@keyframes nextStageAnim {
  0% {
    transform: translateX(0px) translate(-50%, 0);
  }
  50% {
    transform: translateX(10px) translate(-50%, 0);
  }
  100% {
    transform: translateX(0px) translate(-50%, 0);
  }
}

.stage-title-modifier {
  max-width: 85%
}
</style>
